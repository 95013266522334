import React from 'react'
import "./contact.scss"
import BlueRectangle from "../../assets/Rectangle 26.png"
import RedRectangle from "../../assets/Rectangle 31.png"
import Person from "../../assets/Frame2.png"
import Cloud from "../../assets/Frame-1.png"
import BlackFooter from "../../components/footer/BlackFooter";



const Contact = () => {
  return (
    <>
    <div className='contact' >
        <h1 className='head' >Contact</h1>
        <div className='flex' >
        <div className='first' >
            <div className='imgs-container ' >
            <img alt='' className='r-rect' src={RedRectangle} />
            <img alt='' className='b-rect' src={BlueRectangle} />
            <img alt='' className='person' src={Person} />
            {/* <img alt='' className='t-l-cloud' src={Cloud} />
            <img alt='' className='t-r-cloud' src={Cloud} />
            <img alt='' className='m-cloud' src={Cloud} /> */}
            </div>
        </div>
        <div className='sec' >
            <div className='input-div' >
                <span className='label' >Name</span>
                <input type='text' />
            </div>
            <div className='input-div' >
                <span className='label' >Company</span>
                <input type='text' />
            </div>
            <div className='input-div' >
                <span className='label' >Email</span>
                <input type='email' />
            </div>
            <div className='input-div' >
                <span className='label' >Phone</span>
                <input type='tel' />
            </div>
            <button className='btn' >Submit</button>
        </div>
        </div>
    </div>
     <BlackFooter />
     </>
  );
}

export default Contact