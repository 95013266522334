import React, { useState } from "react";
import "./footer.scss";
import FooterImg from "../../assets/hehyer 1.png";
import FooterLogo from "../../assets/Defixerhub-white 1.png";
import TikTok from "../../assets/TikTok.png";
import Tmark from "../../assets/registered-trademark 1.png";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  console.log(email);
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="container">
          <div>
            <span className="text">
              Join our newsletter to <br /> keep up to date with us!
            </span>
          </div>
          <div className="input-submit">
            <div className="input">
              <PersonOutlineIcon />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <button className="submit">Subscribe</button>
          </div>
        </div>
        <div className="container-2">
          <div className="first">
            <img alt="Defixerhub" src={FooterLogo} />
            <span className="brand-txt">
              Nigeria's First All-in-One Hub <br /> for Finding Artisans Easily
            </span>
            <div className="footer-icons">
              <Link to="https://web.facebook.com/Defixerhub/">
                <FacebookRoundedIcon className="footer-icon" />
              </Link>
              <Link to="https://www.instagram.com/defixerhub/">
                <InstagramIcon className="footer-icon" />
              </Link>
              <Link to="https://twitter.com/defixerhub">
                <XIcon className="footer-icon" />
              </Link>
              <img className="footer-icon" alt="tiktok" src={TikTok} />
            </div>
          </div>
          <div className="sec">
            <div className="footer-links">
              <Link to="/">
                <span className="footer-link">Home</span>
              </Link>
              <Link to="/about-us">
                <span className="footer-link">About us</span>
              </Link>
              <Link to="/privacy-policy">
                <span className="footer-link">Privacy policy</span>
              </Link>
              <Link to="/contact">
                <span className="footer-link">contact</span>
              </Link>
            </div>
            <div className="footer-links">
              <Link>
                <span className="footer-link">Join the waitlist</span>
              </Link>
              <Link>
                <span className="footer-link">Sign up</span>
              </Link>
              <Link>
                <span className="footer-link">News</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-3">
          <div className="first">
            <span className="trademark ">
              <img style={{ width: "40px" }} alt="defixerhub" src={Tmark} />{" "}
              <span style={{ marginLeft: "-5px" }}>{year} Defixerhub </span>
            </span>
          </div>
          <div className="sec">
            <Link>
              <span className="l-foot-link">Terms of Service</span>
            </Link>
            <Link>
              <span className="l-foot-link">Privacy policy</span>
            </Link>
            <Link>
              <span className="l-foot-link">Cookies</span>
            </Link>
          </div>
        </div>
      </div>
      <img className="footer-img" alt="footr" src={FooterImg} />
    </div>
  );
};

export default Footer;
