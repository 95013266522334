import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.scss";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import GoogleStore from "../../assets/google-play 1.png";
import WhiteApple from "../../assets/apple 1.png";
import BannerImg from "../../assets/banner-img.png";
import Phone from "../../assets/phone.png";
import Electrician from "../../assets/voltmeter 1.png";
import Plumber from "../../assets/plumber 1.png";
import Carpenter from "../../assets/carpenter 1.png";
import HairDresser from "../../assets/hairdress 1.png";

import Artisan from "../../assets/Frame.png";
import Negotiation from "../../assets/negotiation 1.png";
import Satisfaction from "../../assets/satisfaction (1) 1.png";
import Reputation from "../../assets/reputation 1.png";
import LeftIsolation from "../../assets/Isolation_Mode (2).png";
import RightIsolation from "../../assets/Isolation_Mode.png";
import CenterIsolation from "../../assets/Isolation_Mode (1).png";
import ObjectIsolation from "../../assets/Objects.png";
import Object from "../../assets/Object.png";
import Button from "../../components/button/Button";

import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { withTheme } from "@emotion/react";
import { Slider } from "@mui/material";

const ButtonGroup = ({ next, previous, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
return (
  <div className="carousel-button-group">
    <button className={currentSlide === 0 ? 'disable' : 'prev-and-next'} onClick={() => previous()}>
      <WestIcon className="icon" />
    </button>
    <button className="prev-and-next" onClick={() => next()}>
      <EastIcon className="icon" />
    </button>
  </div>
)
}


const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="home">
        <div className="banner">
          <div className="top">
            <h1>
              Nigeria's First All-in-One Hub <br /> for{" "}
              <span className="dymbruid">Finding Artisans</span> Easily
            </h1>
            <span className="top-text">
              Defixerhub is the trusted marketplace for finding and booking
              skilled <br /> artisans for all your needs. 
            </span>
          </div>
          <div>
              <h3>
                <span style={{ color: "#fa623e" }}>Coming Soon On</span>
              </h3>
            </div>
          <div className="button-div">
            <Link to="/back">
              <button className="download-btn">
                {" "}
                <img alt="" src={GoogleStore} />{" "}
                <span>Download on Google Play</span>{" "}
              </button>
            </Link>
            <Link>
              <button className="download-btn">
                {" "}
                <img style={{ width: "33px" }} alt="" src={WhiteApple} />{" "}
                <span>Download on Apple Store</span>{" "}
              </button>
            </Link>
          </div>
        </div>
        <img alt="" src={BannerImg} className="banner-img" />
        <div className="features">
          <div className="top">
            <div>
              <h3>
                Here's why you'll love <br />{" "}
                <span style={{ color: "#fa623e" }}>Defixerhub</span>
              </h3>
            </div>
            <div className="features-cards">
              <div className="card">
                <span className="card-head">Find</span>
                <p className="p">
                  Discover talented artisans you can trust for any job with our
                  handy app. It's super easy to use! Narrow down your search by
                  location and specific services offered. Find the perfect pro
                  near you, no sweat.
                </p>
              </div>
              <div className="card">
                <span className="card-head">Book</span>
                <p className="p">
                  Schedule appointments in seconds. Look through profiles of
                  many trusted artisans and pick the one that suits you
                  best.Relax knowing all our artisans are carefully checked and
                  reviewed by real customers.
                </p>
              </div>
              <div className="card">
                <span className="card-head">Fix</span>
                <p className="p">
                  Get the job done professionally and pay with ease. No cash
                  hassles! Make secure payments directly through the app using
                  your preferred method.
                </p>
              </div>
            </div>
            <span className="fix-problems-btn">
              Fixing your problems <br /> has never been easier!
            </span>
          </div>
        </div>
        <div className="empowering-phone">
          <div className="empowering">
            <p className="emp-text">
              Empowering Artisans 
              <br /> Get more customers, make more money! Defixerhub connects
              you with tons of Nigerians looking for your skills. With our
              simple app, you can manage everything on your phone - bookings,
              appointments, even getting paid! 
            </p>
            <div className="circles">
              <span className="circle">
                {" "}
                <img
                  alt="Google Store"
                  // style={{ display: "block", margin: "0 auto" }}
                  src={GoogleStore}
                  className="store-icons"
                />{" "}
              </span>
              <span className="circle">
                {" "}
                <img alt="" src={WhiteApple} className="store-icons" />{" "}
              </span>
            </div>
          </div>
          <div className="phone-div">
            <img alt=" " className="obj" src={Object} />
            <div className="phone-bg">
              <img alt="Apple store" className="phone" src={Phone} />
            </div>
          </div>
        </div>
        <div className="categories">
          <div className="cat-top">
            <span className="select-text">Select Category</span>
            <span className="cat-btn">
              <button className="prev-and-next">
                <WestIcon className="icon" />{" "}
              </button>
              <button className="prev-and-next">
                <EastIcon className="icon" />
              </button>
            </span>
          </div>
          <div className="cat-bottom">
            <Carousel
            renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}
              swipeable={true}
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              responsive={responsive}
              infinite={true}
            >
              <Link>
                <div className="artisan-box">
                  <img
                    className="art-img"
                    alt="electrician"
                    src={Electrician}
                  />
                  <span className="artisan-text">Electrician</span>{" "}
                </div>
              </Link>
              <Link>
                <div className="artisan-box">
                  <img className="art-img" alt="electrician" src={Plumber} />
                  <span className="artisan-text">Plumber</span>{" "}
                </div>
              </Link>
              <Link>
                <div className="artisan-box">
                  <img className="art-img" alt="electrician" src={Carpenter} />
                  <span className="artisan-text">Carpenter</span>{" "}
                </div>
              </Link>
              <Link>
                <div className="artisan-box">
                  <img
                    className="art-img"
                    alt="electrician"
                    src={HairDresser}
                  />
                  <span className="artisan-text">Hair dresser</span>{" "}
                </div>
              </Link>
              <Link>
                <div className="artisan-box">
                  <img
                    className="art-img"
                    alt="electrician"
                    src={HairDresser}
                  />
                  <span className="artisan-text">Makeup artist</span>{" "}
                </div>
              </Link>
              <Link>
                <div className="artisan-box">
                  <img
                    className="art-img"
                    alt="electrician"
                    src={Electrician}
                  />
                  <span className="artisan-text">Electrician</span>{" "}
                </div>
              </Link>
            </Carousel>
          </div>
        </div>
        <div className="become-a-fixer">
          <div className="first">
            <img alt="" className="obj-isolation" src={ObjectIsolation} />
            <span className="fixer-text">
              Become <br /> a Fixer!
            </span>
            <Link to="https://bit.ly/DefixerhubWaitlist">
              <button className="sign-up">Sign up now</button>
            </Link>
          </div>
          <div>
            <img className="artisan" alt="Artisan" src={Artisan} />
          </div>
        </div>
        <div className="features-with-image">
          <div className="features-container">
            <div className="white-circle">
              <img className="circle-img" alt="negotiation" src={Negotiation} />
            </div>
            <p className="text">
              Sign up to grow your business and land more jobs with less stress
            </p>
          </div>
          <div className="features-container">
            <div className="white-circle">
              <img
                className="circle-img"
                alt="negotiation"
                src={Satisfaction}
              />
            </div>
            <p className="text">
               Fulfill customer requests and get paid for doing what you're
              already great at.
            </p>
          </div>
          <div className="features-container">
            <div className="white-circle">
              <img className="circle-img" alt="negotiation" src={Reputation} />
            </div>
            <p className="text">
              The more positive feedback you receive, the more clients you'll
              attract
            </p>
          </div>
        </div>
        <div className="last-div-container">
          <img src={LeftIsolation} className="left-isolation" alt="" />
          <img src={RightIsolation} className="right-isolation" alt="" />

          <div className="luanch-div">
            <img alt="" className="center-isolation" src={CenterIsolation} />
            <span className="luanch-txt">
              We're about <br />
              to launch.
            </span>
            <Link to="https://bit.ly/DefixerhubWaitlist">
              <button className="join-btn">Join the waitlist</button>
            </Link>
          </div>
          <div className="box-container">
            <img alt="" className="obj-isolation" src={ObjectIsolation} />
            <div className="box">
              <span className="box-txt">
                Join the waitlist to be an early insider and get exclusive
                updates when we launch. Don't miss out - be a part of our
                awesome community of pros and customers from the start!
              </span>
            </div>
          </div>
          <img src={LeftIsolation} className="bottom-left-isolation" alt="" />
          <img src={RightIsolation} className="bottom-right-isolation" alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
