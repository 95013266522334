import React from "react";
import "./about.scss";
import BlackFooter from "../../components/footer/BlackFooter";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="span">
          <span className="head">About us</span>
          <span className="txt">
          Defixerhub is more than just a hiring platform; we connect Nigerians 
          with talented artisans and skilled professionals, bridging the gap 
          between those who need a task done and those who excel at doing it. 
          We’re a community that celebrates craftsmanship and expertise. 
          We empower individuals to showcase their talents and connect them with 
          those who need their unique skills.
          </span>
        </div>
        <div className="span">
          <span className="head">Mission Statement</span>
          <span className="txt">
          Our mission is to connect skilled people with those who need their talents. 
          We achieve this by carefully selecting talented professionals and artisans 
          to join our community. At Defixerhub, we go beyond connecting people; 
          we make sure you can communicate smoothly and get high-quality work done. 
          We value expertise and craftsmanship, and want to help people share their 
          skills and succeed.  By connecting the right people, Defixerhub helps both 
          clients and professionals achieve their goals.
          </span>
        </div>
        <div className="span">
          <span className="head">Vision</span>
          <span className="txt">
          We envision a future where the talent gap in Nigeria is a distant memory, 
          ensuring that anyone with a smartphone can access the services they need 
          easily. We are transforming the way Nigerians connect with skilled 
          professionals and artisans, which is built on trust and ease. We aim to 
          create a sustainable system where over 20 million skilled professionals 
          and artisans across Nigeria can effortlessly connect with clients seeking 
          their expertise.
          </span>
        </div>
      </div>
      <BlackFooter />
    </>
  );
};

export default About;
