import React from "react";
import "./privacy.scss";
import BlackFooter from "../../components/footer/BlackFooter";

const Privacy = () => {
  return (
    <>
      <div className="privacy">
        <div className="span">
          <span className="head">Privacy Policy</span>
          <span className="txt">
          <p>
            Free privacy policy: cover
          </p>
          <p>
          <span className="txt">
            1. This template legal document was produced and published by
            Docular Limited.
            </span>
          </p>
          <p>
            2. We control the copyright in this template, and you may only use
            this template in accordance with the licensing provisions in our
            terms and conditions. Those licensing provisions include an
            obligation to retain the attribution/credit incorporated into the
            template.
          </p>
          <p>
            3. Buy a licence to use this template without the attribution/credit
            here:{" "}
            <a href="https://www.website-contracts.co.uk/privacy-policy.html">
              https://www.website-contracts.co.uk/privacy-policy.html
            </a>
          </p>
          <p>
            4. You will need to edit this template before use. Guidance notes to
            help you do so are set out at the end of the template. During the
            editing process, you should delete those guidance notes and this
            cover sheet. Square brackets in the body of the document indicate
            areas that require editorial attention. "ORs" in the body of the
            document indicate alternative provisions. By the end of the editing
            process, there should be no square brackets left in the body of the
            document, and only one alternative from each set of alternatives
            should remain. Elements may be specified as optional in the
            accompanying notes, but that does not mean that they are in all
            cases removable. Nor are they always retainable. Depending upon the
            circumstances, the inclusion or removal of an optional element may
            be: (i) required by law; or (ii) necessary to ensure that the
            document is internally consistent.
          </p>
          <p>
            5. If you have any doubts about the editing or use of this template,
            you should seek professional legal advice.
          </p>
          <p>
            6. Get a quote for the completion of this template here:{" "}
            <a href="https://seqlegal.com/request-quote">
              https://seqlegal.com/request-quote
            </a>
          </p>
          </span>
        </div>

        <section>
          <div className="span">
          <span className="head">Privacy policy</span>
          <h3>
                <span style={{ color: "#ffb19f" }}>1. Introduction</span>
              </h3>
          <span className="txt">
          <p>
            1.1 We are committed to safeguarding the privacy of [our website
            visitors, service users, individual customers and customer
            personnel].         
          </p>
          <p>       
            1.2 This policy applies where we are acting as a data controller
            with respect to the personal data of such persons; in other words,
            where we determine the purposes and means of the processing of that
            personal data.         
          </p>
          <p> 
            1.3 Our website incorporates privacy controls which affect how we
            will process your personal data. By using the privacy controls, you
            can [specify whether you would like to receive direct marketing
            communications and limit the collection, sharing and publication of
            your personal data]. You can access the privacy controls via [URL].
          </p>
          <p>
            1.4 We use cookies on our website. Insofar as those cookies are not
            strictly necessary for the provision of [our website and services],
            we will ask you to consent to our use of cookies when you first
            visit our website.         
          </p>
          <p>
            1.5 In this policy, "we", "us" and "our" refer to [data controller
            name].[ For more information about us, see Section 14.]
          </p>
          </span>
          </div>
        </section>

        <section>
        <h3>
                <span style={{ color: "#ffb19f" }}>2. Credit</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
          <p>
            2.1 This document was created using a template from Docular (
            <a href="https://seqlegal.com/free-legal-documents/privacy-policy">
              https://seqlegal.com/free-legal-documents/privacy-policy
            </a>
            ).
          </p>
          <p>
            You must retain the above credit. Use of this document without the
            credit is an infringement of copyright. However, you can purchase
            from us an equivalent document that does not include the credit.
          </p>
          </span>
        </section>

        <section>
        <h3>
                <span style={{ color: "#ffb19f" }}>3. The personal data that we collect</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
          <p>
            3.1 In this Section 3 we have set out the general categories of
            personal data that we process[ and, in the case of personal data
            that we did not obtain directly from you, information about the
            source and specific categories of that data].
          </p>
          <p>
            3.2 We may process data enabling us to get in touch with you
            ("contact data").[ The contact data may include [your name, email
            address, telephone number, postal address and/or social media
            account identifiers].][ The source of the contact data is [you
            and/or your employer].][ If you log into our website using a social
            media account, we will obtain elements of the contact data from the
            relevant social media account provider.]
          </p>
          <p>
            3.3 We may process [your website user account data] ("account
            data").[ The account data may [include your account identifier,
            name, email address, business name, account creation and
            modification dates, website settings and marketing preferences].][
            The primary source of the account data is [you and/or your employer,
            although some elements of the account data may be generated by our
            website].][ If you log into our website using a social media
            account, we will obtain elements of the account data from the
            relevant social media account provider.]
          </p>
          <p>
            3.4 We may process [information relating to transactions, including
            purchases of goods and/or services, that you enter into with us
            and/or through our website] ("transaction data").[ The transaction
            data may include [your name, your contact details, your payment card
            details (or other payment details) and the transaction details].][
            The source of the transaction data is [you and/or our payment
            services provider].]
          </p>
          <p>
            3.5 We may process [information contained in or relating to any
            communication that you send to us or that we send to you]
            ("communication data"). The communication data may include [the
            communication content and metadata associated with the
            communication].[ Our website will generate the metadata associated
            with communications made using the website contact forms.]
          </p>
          <p>
            3.6 We may process [data about your use of our website and services]
            ("usage data"). The usage data may include [your IP address,
            geographical location, browser type and version, operating system,
            referral source, length of visit, page views and website navigation
            paths, as well as information about the timing, frequency and
            pattern of your service use]. The source of the usage data is [our
            analytics tracking system].
          </p>
          <p>
            3.7 We may process [identify general category of data].[ This data
            may include [list specific items of data].][ The source of this data
            is [identify source].]
          </p>
          </span>
        </section>

        <section>
        <h3>
            <span style={{ color: "#ffb19f" }}>4. Purposes of processing and legal bases</span>
         </h3>
          <span style={{ color: "#fff" }} className="txt">
          <p>
            4.1 In this Section 4, we have set out the purposes for which we may
            process personal data and the legal bases of the processing.
          </p>
          <p>
            4.2 Operations - We may process [your personal data] for [the
            purposes of operating our website, the processing and fulfilment of
            orders, providing our services, supplying our goods, generating
            invoices, bills and other payment-related documentation, and credit
            control]. The legal basis for this processing is [our legitimate
            interests, namely [the proper administration of our website,
            services and business]] OR [the performance of a contract between
            you and us and/or taking steps, at your request, to enter into such
            a contract] OR [[specify basis]].
          </p>
          <p>
            4.3 Publications - We may process [account data] for [the purposes
            of publishing such data on our website and elsewhere through our
            services in accordance with your express instructions]. The legal
            basis for this processing is [consent] OR [our legitimate interests,
            namely [the publication of content in the ordinary course of our
            operations]] OR [the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract]
            OR [[specify basis]].
          </p>
          <p>
            4.4 Relationships and communications - We may process [contact data,
            account data, transaction data and/or communication data] for [the
            purposes of managing our relationships, communicating with you
            (excluding communicating for the purposes of direct marketing) by
            email, SMS, post, fax and/or telephone, providing support services
            and complaint handling]. The legal basis for this processing is [our
            legitimate interests, namely [communications with our website
            visitors, service users, individual customers and customer
            personnel, the maintenance of relationships, and the proper
            administration of our website, services and business]] OR [[specify
            basis]].
          </p>
          <p>
            4.5 Direct marketing - We may process [contact data, account data
            and/or transaction data] for [the purposes of creating, targeting
            and sending direct marketing communications by email, SMS, post
            and/or fax and making contact by telephone for marketing-related
            purposes]. The legal basis for this processing is [consent] OR [our
            legitimate interests, namely [promoting our business and
            communicating marketing messages and offers to our website visitors
            and service users]] OR [[specify basis]].
          </p>
          <p>
            4.6 Research and analysis - We may process [usage data and/or
            transaction data] for [the purposes of researching and analysing the
            use of our website and services, as well as researching and
            analysing other interactions with our business]. The legal basis for
            this processing is [consent] OR [our legitimate interests, namely
            [monitoring, supporting, improving and securing our website,
            services and business generally]] OR [[specify basis]].
          </p>
          <p>
            4.7 Record keeping - We may process [your personal data] for [the
            purposes of creating and maintaining our databases, back-up copies
            of our databases and our business records generally]. The legal
            basis for this processing is [our legitimate interests, namely
            [ensuring that we have access to all the information we need to run
            our business properly and efficiently in accordance with this
            policy]] OR [[specify basis]].
          </p>
          <p>
            4.8 Security - We may process [your personal data] for [the purposes
            of security and the prevention of fraud and other criminal
            activity]. The legal basis for this processing is [our legitimate
            interests, namely [the protection of our website, services and
            business, and the protection of others]] OR [[specify basis]].
          </p>
          <p>
            4.9 Insurance and risk management - We may process [your personal
            data] for [the purposes of obtaining or maintaining insurance
            coverage, managing risks and/or obtaining professional advice]. The
            legal basis for this processing is [our legitimate interests, namely
            [the proper protection of our business against risks]] OR [[specify
            basis]].
          </p>
          <p>
            4.10 Legal claims - We may process [your personal data] where
            necessary for [the establishment, exercise or defence of legal
            claims, whether in court proceedings or in an administrative or
            out-of-court procedure]. The legal basis for this processing is [our
            legitimate interests, namely [the protection and assertion of our
            legal rights, your legal rights and the legal rights of others]] OR
            [the protection and assertion of our legal rights, your legal rights
            and the legal rights of others] OR [[specify basis]].
          </p>
          <p>
            4.11 Legal compliance and vital interests - We may also process
            [your personal data] where such processing is [necessary] for
            [compliance with a legal obligation to which we are subject] or [in
            order to protect your vital interests or the vital interests of
            another natural person].
          </p>
          </span>
        </section>

        <section>
        <h3>
                <span style={{ color: "#ffb19f" }}>5. Providing your personal data to others</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
          <p>
            5.1 We may disclose [your personal data] to [our insurers and/or
            professional advisers] insofar as reasonably necessary for [the
            purposes of obtaining or maintaining insurance coverage, managing
            risks, obtaining professional advice, or the establishment, exercise
            or defence of legal claims, whether in court proceedings or in an
            administrative or out-of-court procedure].
          </p>
          <p>
            5.2 Your personal data held in our website database will be stored
            on the servers of our hosting services providers identified at
            [URL].
          </p>
          <p>
            5.3 We may disclose [specified categories of your personal data] to
            [our suppliers or subcontractors] insofar as reasonably necessary
            for [specified purposes].
          </p>
          <p>
            5.4 Financial transactions relating to [our website and services]
            [are OR may be] handled by our payment services providers, [PayPal,
            Stripe, etc.]. We will share transaction data with our payment
            services providers only to the extent necessary for the purposes of
            [processing your payments, refunding such payments and dealing with
            complaints and queries relating to such payments and refunds]. You
            can find information about the payment services providers' privacy
            policies and practices at [URLs].
          </p>
          <p>
            5.5 We may disclose [your enquiry data] to [one or more of those
            selected third party suppliers of goods and services identified on
            our website] for the purpose of [enabling them to contact you so
            that they can offer, market and sell to you relevant goods and/or
            services]. Each such third party will act as a data controller in
            relation to the enquiry data that we supply to it; and upon
            contacting you, each such third party will supply to you a copy of
            its own privacy policy, which will govern that third party's use of
            your personal data.
          </p>
          <p>
            5.6 In addition to the specific disclosures of personal data set out
            in this Section 5, we may disclose your personal data where such
            disclosure is necessary for compliance with a legal obligation to
            which we are subject, or in order to protect your vital interests or
            the vital interests of another natural person. We may also disclose
            your personal data where such disclosure is necessary for the
            establishment, exercise or defence of legal claims, whether in court
            proceedings or in an administrative or out-of-court procedure.
          </p>
          </span>
        </section>

        <section>
        <h3>
                <span style={{ color: "#ffb19f" }}>6. International transfers of your personal data</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
          <p>
            6.1 In this Section 6, we provide information about the
            circumstances in which your personal data may be transferred to
            countries outside the European Economic Area (EEA).
          </p>
          <p>
            6.2 We [and our other group companies] have [offices and facilities]
            in [specify countries]. The European Commission has made an
            "adequacy decision" with respect to [the data protection laws of
            each of these countries]. Transfers to [each of these countries]
            will be protected by appropriate safeguards, namely [the use of
            standard data protection clauses adopted or approved by the European
            Commission, a copy of which you can obtain from [source] OR [specify
            other appropriate safeguards and means of obtaining a copy]].
          </p>
          <p>
            6.3 The hosting facilities for our website are situated in [specify
            countries]. The European Commission has made an "adequacy decision"
            with respect to [the data protection laws of each of these
            countries]. Transfers to [each of these countries] will be protected
            by appropriate safeguards, namely [the use of standard data
            protection clauses adopted or approved by the European Commission, a
            copy of which you can obtain from [source] OR [specify other
            appropriate safeguards and means of obtaining a copy]].
          </p>
          <p>
            6.4 [Specify category or categories of supplier or subcontractor]
            [are OR may be] situated in [specify countries]. The European
            Commission has made an "adequacy decision" with respect to [the data
            protection laws of each of these countries]. Transfers to [each of
            these countries] will be protected by appropriate safeguards, namely
            [the use of standard data protection clauses adopted or approved by
            the European Commission, a copy of which you can obtain from
            [source] OR [specify other appropriate safeguards and means of
            obtaining a copy]].
          </p>
          <p>
            6.5 You acknowledge that [personal data that you submit for
            publication through our website or services] may be available, via
            the internet, around the world. We cannot prevent the use (or
            misuse) of such personal data by others.
          </p>
          </span>
        </section>
        <h3>
                <span style={{ color: "#ffb19f" }}>7. Retaining and deleting personal data</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
        <p>
          7.1 This Section 7 sets out our data retention policies and
          procedures, which are designed to help ensure that we comply with our
          legal obligations in relation to the retention and deletion of
          personal data.
        </p>
        <p>
          7.2 Personal data that we process for any purpose or purposes shall
          not be kept for longer than is necessary for that purpose or those
          purposes.
        </p>
        <p>7.3 We will retain your personal data as follows:</p>
        <ul>
          <li>
            (a) [contact data will be retained for a minimum period of [period]
            following the date of the most recent contact between you and us,
            and for a maximum period of [period] following that date];
          </li>
          <li>
            (b) [account data will be retained for a minimum period of [period]
            following the date of closure of the relevant account, and for a
            maximum period of [period] following that date];
          </li>
          <li>
            (c) [transaction data will be retained for a minimum period of
            [period] following the date of the transaction, and for a maximum
            period of [period] following that date];
          </li>
          <li>
            (d) [communication data will be retained for a minimum period of
            [period] following the date of the communication in question, and
            for a maximum period of [period] following that date];
          </li>
          <li>
            (e) [usage data will be retained for [period] following the date of
            collection]; and
          </li>
          <li>
            (f) [[data category] will be retained for a minimum period of
            [period] following [date], and for a maximum period of [period]
            following [date]].
          </li>
        </ul>
        <p>
          7.4 Notwithstanding the other provisions of this Section 7, we may
          retain your personal data where such retention is necessary for
          compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person.
        </p>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>8. Your rights</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
        <p>
          8.1 In this Section 8, we have listed the rights that you have under
          data protection law.
        </p>
        <p>8.2 Your principal rights under data protection law are:</p>
        <ul>
          <li>
            (a) the right to access - you can ask for copies of your personal
            data;
          </li>
          <li>
            (b) the right to rectification - you can ask us to rectify
            inaccurate personal data and to complete incomplete personal data;
          </li>
          <li>
            (c) the right to erasure - you can ask us to erase your personal
            data;
          </li>
          <li>
            (d) the right to restrict processing - you can ask us to restrict
            the processing of your personal data;
          </li>
          <li>
            (e) the right to object to processing - you can object to the
            processing of your personal data;
          </li>
          <li>
            (f) the right to data portability - you can ask that we transfer
            your personal data to another organisation or to you;
          </li>
          <li>
            (g) the right to complain to a supervisory authority - you can
            complain about our processing of your personal data; and
          </li>
          <li>
            (h) the right to withdraw consent - to the extent that the legal
            basis of our processing of your personal data is consent, you can
            withdraw that consent.
          </li>
        </ul>
        <p>
          8.3 These rights are subject to certain limitations and exceptions.
          You can learn more about the rights of data subjects by visiting{" "}
          <a href="https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en">
            https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en
          </a>{" "}
          and{" "}
          <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
            https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
          </a>
          .
        </p>
        <p>
          8.4 You may exercise any of your rights in relation to your personal
          data [by written notice to us, using the contact details set out
          below].
        </p>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>9. About cookies</span>
              </h3>
        <span style={{ color: "#fff" }} className="txt">
        <p>
          9.1 A cookie is a file containing an identifier (a string of letters
          and numbers) that is sent by a web server to a web browser and is
          stored by the browser. The identifier is then sent back to the server
          each time the browser requests a page from the server.
        </p>
        <p>
          9.2 Cookies may be either "persistent" cookies or "session" cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed.
        </p>
        <p>
          9.3 Cookies may not contain any information that personally identifies
          a user, but personal data that we store about you may be linked to the
          information stored in and obtained from cookies.
        </p>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>10. Cookies that we use</span>
              </h3>
               <span style={{ color: "#fff" }} className="txt">
        <p>10.1 We use cookies for the following purposes:</p>
        <ul>
          <li>
            (a) [authentication and status - we use cookies [to identify you
            when you visit our website and as you navigate our website, and to
            help us determine if you are logged into our website][ (cookies used
            for this purpose are: [identify cookies])]];
          </li>
          <li>
            (b) [shopping cart - we use cookies to [maintain the state of your
            shopping cart as you navigate our website][ (cookies used for this
            purpose are: [identify cookies])]];
          </li>
          <li>
            (c) [personalisation - we use cookies [to store information about
            your preferences and to personalise our website for you][ (cookies
            used for this purpose are: [identify cookies])]];
          </li>
          <li>
            (d) [security - we use cookies [as an element of the security
            measures used to protect user accounts, including preventing
            fraudulent use of login credentials, and to protect our website and
            services generally][ (cookies used for this purpose are: [identify
            cookies])]];
          </li>
          <li>
            (e) [advertising - we use cookies [to help us to display
            advertisements that will be relevant to you][ (cookies used for this
            purpose are: [identify cookies])]];
          </li>
          <li>
            (f) [analysis - we use cookies [to help us to analyse the use and
            performance of our website and services][ (cookies used for this
            purpose are: [identify cookies])]]; and
          </li>
          <li>
            (g) [cookie consent - we use cookies [to store your preferences in
            relation to the use of cookies more generally][ (cookies used for
            this purpose are: [identify cookies])]].
          </li>
        </ul>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>11. Cookies used by our service providers</span>
              </h3>
        <span style={{ color: "#fff" }} className="txt">
        <p>
          11.1 Our service providers use cookies and those cookies may be stored
          on your computer when you visit our website.
        </p>
        <p>
          11.2 We use Google Analytics, which gathers information about the use
          of our website and uses cookies for this purpose. We use the
          information gathered by Google Analytics to create reports about the
          use of our website. You can find out more about Google's use of
          information by visiting{" "}
          <a href="https://policies.google.com/technologies/partner-sites">
            https://policies.google.com/technologies/partner-sites
          </a>{" "}
          and you can review Google's privacy policy at{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          . The cookies used by Google Analytics are named _ga and
          _ga+container-id.
        </p>
        <p>
          11.3 We use [identify service provider] to [specify service]. This
          service uses cookies for [specify purpose(s)]. You can view the
          privacy policy of this service provider at [URL]. [The relevant
          cookies are: [identify cookies].]
        </p>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>12. Managing cookies</span>
              </h3>
       <span style={{ color: "#fff" }} className="txt">
        <p>
          12.1 Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser and
          from version to version. You can obtain up-to-date information about
          managing cookies via these links:
        </p>
        <ul>
          <li>
            (a){" "}
            <a href="https://support.google.com/chrome/answer/95647">
              https://support.google.com/chrome/answer/95647 (Chrome)
            </a>
            ;
          </li>
          <li>
            (b){" "}
            <a href="https://support.mozilla.org/en-US/products/firefox/privacy-and-security">
              https://support.mozilla.org/en-US/products/firefox/privacy-and-security
              (Firefox)
            </a>
            ;
          </li>
          <li>
            (c){" "}
            <a href="https://help.opera.com/en/latest/security-and-privacy/">
              https://help.opera.com/en/latest/security-and-privacy/ (Opera)
            </a>
            ;
          </li>
          <li>
            (d){" "}
            <a href="https://support.apple.com/en-gb/guide/safari/welcome/mac">
              https://support.apple.com/en-gb/guide/safari/welcome/mac (Safari)
            </a>
            ;
          </li>
          <li>
            (e){" "}
            <a href="https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
              https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
              (Edge)
            </a>
            .
          </li>
        </ul>
        <p>
          12.2 Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </p>
        <p>
          12.3 If you block cookies, you will not be able to use all the
          features on our website.
        </p>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>13. Amendments</span>
              </h3>
        <span style={{ color: "#fff" }} className="txt">
        <p>
          13.1 We may update this policy from time to time by publishing a new
          version on our website.
        </p>
        <p>
          13.2 You should check this page occasionally to ensure you are happy
          with any changes to this policy.
        </p>
        <p>
          13.3 We [may] OR [will] notify you of [changes] OR [significant
          changes] to this policy [by email].
        </p>
        </span>
        <h3>
            <span style={{ color: "#ffb19f" }}>14. Our details</span>
         </h3>
         <span style={{ color: "#fff" }} className="txt">
        <p>14.1 This website is owned and operated by [name].</p>
        <p>
          14.2 We are registered in [jurisdiction] under registration number
          [number], and our registered office is at [address].
        </p>
        <p>14.3 Our principal place of business is at [address].</p>
        <p>14.4 You can contact us:</p>
        <ul>
          <li>(a) by post, to [the postal address given above];</li>
          <li>(b) using our website contact form;</li>
          <li>
            (c) by telephone, on [the contact number published on our website
            from time to time]; or
          </li>
          <li>
            (d) by email, using [the email address published on our website from
            time to time].
          </li>
        </ul>
        </span>
        <h3>
                <span style={{ color: "#ffb19f" }}>15. Data protection officer</span>
              </h3>
        <p>
      
          <span style={{ color: "#fff" }} className="txt">
          15.1 Our data protection officer's contact details are: [contact
          details].
          </span>
        </p>
      </div>
      <BlackFooter />
    </>
  );
};

export default Privacy;
