// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/navbar/Navigation"

import Home from "./pages/home/Home";
import About from "./pages/about/About"
import Privacy from "./pages/privacy/Privacy";
import Contact from "./pages/contact/Contact";
import Delete from "./pages/delete/Delete";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Router>
      <Navigation/>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/delete" element={<Delete />} />


      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
