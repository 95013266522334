// import React, { useState } from "react";
// import Logo from "../../assets/logo2.png";
// import { Link } from "react-router-dom";
// import "./navbar.scss";

// const Navigation = () => {
//   const [active, setActiv] = "";

//   return (
//     <div className="navbar">
//       <div className="logo-container">
//         <img alt="" src={Logo} />
//       </div>
//       <div className="nav-item-container">
//         <div className="nav-links">
//           <Link>
//             <span className="nav-item">Home</span>
//           </Link>
//           <Link>
//             <span className="nav-item">About us</span>
//           </Link>
//           <Link>
//             <span className="nav-item">Privacy policy</span>
//           </Link>
//           <Link>
//             <span className="nav-item">Contact</span>
//           </Link>
//         </div>
//         <div className="nav-btn-div">
//           <Link>
//             <button className="nav-btn">Book us</button>
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navigation;

import Logo from "../../assets/logo2.png";
import { NavLink } from "react-router-dom";
import "./navbar.scss";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";



const Navigation = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };



  return (
    <div className="navbar">
      <div className="logo-container">
        <img alt="" src={Logo} />
      </div>
      <div className="nav-item-container">
        <div className="nav-links">
          <NavLink exact to="/" activeClassName="active-nav-item">
            <span className="nav-item">Home</span>
          </NavLink>
          <NavLink to="/about-us" activeClassName="active-nav-item">
            <span className="nav-item">About us</span>
          </NavLink>
          <NavLink to="/privacy-policy" activeClassName="active-nav-item">
            <span className="nav-item">Privacy policy</span>
          </NavLink>
          <NavLink to="/contact" activeClassName="active-nav-item">
            <span className="nav-item">Contact</span>
          </NavLink>
        </div>
        <div className="nav-btn-div">
          <NavLink to="https://web.defixerhub.com/">
            <button className="nav-btn">Book us</button>
          </NavLink>
        </div>

      </div>
      <button onClick={toggleDropdown} className="mn-btn d-lg-none">
        <MenuIcon />
      </button>
      {isDropdownOpen && (
              <div className="nav-item-container-side">
              <div className="nav-links">
                <NavLink exact to="/" activeClassName="active-nav-item">
                  <span className="nav-item">Home</span>
                </NavLink>
                <NavLink to="/about-us" activeClassName="active-nav-item">
                  <span className="nav-item">About us</span>
                </NavLink>
                <NavLink to="/privacy-policy" activeClassName="active-nav-item">
                  <span className="nav-item">Privacy policy</span>
                </NavLink>
                <NavLink to="/contact" activeClassName="active-nav-item">
                  <span className="nav-item">Contact</span>
                </NavLink>
              </div>
              <div className="nav-btn-div">
                <NavLink to="/book-us">
                  <button className="nav-btn">Book us</button>
                </NavLink>
              </div>
      
            </div>
      
          )}

    </div>
  );
};

export default Navigation;

